import React from 'react';
import { Dispatch, Textbox, SubmitButton } from '@Corim/architecture-frontend'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { of } from 'rxjs'
import { delay, tap, filter } from 'rxjs/operators'
import loading from '../../media/loading.svg'

import _ from 'lodash'

import './contactEngine'

export default (props) => {
  return (
    <div id='contact' className='relative flex justify-center' style={{top: 0}}>

        <div className='pt5 mw6 w-100 ph4'>
          <div className='w-100 f1 fw8 pink'>Contact</div>
          <Textbox
            className='w-100 mt3 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.name'
            placeholder='Name'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.email'
            placeholder='Email'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv4 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.message'
            placeholder='Message'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <div className='pt3 w-100 flex justify-between'>
            <div>
            </div>
            <SubmitButton id={'contactSubmit'} className='flex pt2 justify-between' 
              showSuccess={true}
              defaultChild={ props => (
                <div className='dib ph4 pv3 br2 bg-pink white pointer fw6' onClick={e => Dispatch.nextAction2('contact.send')}>Submit</div>
              )}
              loadingChild={ props => (
                <div className='w-100 flex justify-between'>
                  <div></div>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              successChild={ props => (
                <div className='dib ph4 pv3 br2 bg-pink white pointer fw6'>Success</div>
              )}
              errorChild={ props => (
                <div className='pl3 f5 fw7 red'>Error</div>
              )}
              loadingStream={
                Dispatch.getAction2('Request.contact')
                }
              successStream={
                Dispatch.getAction2('Response.contact')
                  .pipe(
                    delay(500),
                    filter(e => _.isNil(e.errors)),
                  )
                }
              errorStream={Dispatch.getAction2('Response.contact')
              .pipe(
                filter(e => !_.isNil(e.errors)),
              )}
            />
          </div>
        </div>
        </div>
        )
  }