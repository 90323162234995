import React, { Component } from 'react';
import { BaseComponent } from '@Corim/architecture-frontend'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faSmile } from '@fortawesome/free-solid-svg-icons'
import { faSnowflake } from '@fortawesome/free-regular-svg-icons'
import position from './start'
import SnowFlake from './Snowflake.svg'

import { of } from 'rxjs'
import { delay, tap } from 'rxjs/operators'

// const Snowflake = (props) => {
//   const snowflakes = props.balls ? props.balls : {}
//   return (
//     <div className='' style={{pointerEvents:'none'}} style={{MozUserSelect:'none', WebkitUserSelect:'none', msUserSelect:'none'}}>
//       {
//         Object.keys(snowflakes).map(key => <div key={key} className='fixed w3 h3' style={{top: snowflakes[key].space + snowflakes[key].y + 70, left: snowflakes[key].x, zIndex: 10000, pointerEvents:'none', MozUserSelect:'none', WebkitUserSelect:'none', msUserSelect:'none'}}>
//           <FontAwesomeIcon className='f3 pulse pointer grow white' icon={faSnowflake} />
//         </div>)
//       }
//     </div>
//   )
// }

const map2$ = position.pipe(
  tap(e => {
    const snowflakes = e.balls
    var c = document.getElementById("mapCanvas");
    var ctx = c.getContext("2d");
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    Object.keys(snowflakes).map(key => {
      const image = document.getElementById("snowflake")
      ctx.drawImage(image, snowflakes[key].x, snowflakes[key].space + snowflakes[key].y + 70, 25, 25);
    })
  }),
)

const Snowflake = (props) => {
  return (
    <div className='w-100 vh-100 fixed' style={{ zIndex: 10000, pointerEvents:'none', MozUserSelect:'none', WebkitUserSelect:'none', msUserSelect:'none'}}>
      <canvas id="mapCanvas" width={window.innerWidth}  height={window.innerHeight} style={{ pointerEvents:'none', MozUserSelect:'none', WebkitUserSelect:'none', msUserSelect:'none'}}>
      </canvas>
      <div className='dn'>
        <img id="snowflake" src={SnowFlake}/>
      </div>
    </div>
  )
}
export default (props) => <BaseComponent Component={Snowflake} store={map2$} {...props}/>