import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Dispatch } from '@Corim/architecture-frontend'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import history from '../history'

import { of } from 'rxjs'
import { delay, tap } from 'rxjs/operators'


class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (name) => {
    this.setState({ anchorEl: null });
    //Dispatch.nextAction('scroll', name)
    //document.getElementById('home').scrollIntoView()
    //document.getElementById('contact').scrollIntoView()
  //  e.preventDefault()
  };
  handleClickItem = name => {
    history.push('/')
    of(1)
    .pipe(
      //delay(500),
      //tap(e => document.getElementById(name).scrollIntoView()),
      tap(e => this.setState({ anchorEl: null }))
    )
    .subscribe(e => {
      // const element = document.getElementById(name)
      // element.scrollIntoView()
      // element.scrollTop += 200
      //document.scroll

      const yOffset = -50; 
      const element = document.getElementById(name);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    })
    // document.getElementById(name).scrollIntoView()
    // this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div style={{zIndex:10000}}>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <div className='f2 pink'><FontAwesomeIcon icon={faBars} /></div>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{zIndex:100000}}
        >
          <MenuItem onClick={e => this.handleClickItem('watch')}><span className='avenir ph3 f5 fw5 '> Intro </span></MenuItem>
          <MenuItem onClick={e => this.handleClickItem('attractions')}><span className='avenir ph3 f5 fw5 '>Attractions</span></MenuItem>
          <MenuItem onClick={e => this.handleClickItem('getinvolved')}><span className='avenir ph3 f5 fw5 '>Get Involved</span></MenuItem>
          {/* <MenuItem onClick={e => this.handleClickItem('simulator')}><span className='avenir ph3 f5 fw5'>The Simulator</span></MenuItem> */}
          <MenuItem onClick={e => this.handleClickItem('faq')}><span className='avenir ph3 f5 fw5'>FAQ</span></MenuItem>
          <MenuItem onClick={e => this.handleClickItem('contact')}><span className='avenir ph3 f5 fw5'>Contact</span></MenuItem>
          <MenuItem onClick={e => this.handleClickItem('sponsors')}><span className='avenir ph3 f5 fw5 pink'>Sponsor!</span></MenuItem>
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
