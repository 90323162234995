import { gql } from 'apollo-boost';
import { Dispatch } from '@Corim/architecture-frontend'
import { combineLatest } from 'rxjs'
import { map, distinctUntilChanged, withLatestFrom, filter } from 'rxjs/operators'

import _ from 'lodash'

const SEND_CONTACT = gql`
mutation contact($name: String! $email: String! $message: String!) {
  contact(name: $name email: $email message: $message) {
    success
  }
}
`;

Dispatch.getAction2('contact.send')
  .pipe(
    withLatestFrom(Dispatch.getState('Textbox.contact'))
  )
  .subscribe(([send, contact]) => Dispatch.nextAction2('Request.contact', { type: 'mutation', query: SEND_CONTACT, variables: { name:contact.name, email:contact.email, message:contact.message + ' ... THIS IS FROM THE LIGHT SHOW' } }))

Dispatch.getAction2('Response.contact')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.contact.success),
      filter(e => e === true)
    )
  .subscribe(e => Dispatch.mergeState('Textbox.contact', {name: '', email: '', message: ''}))