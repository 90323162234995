import React from 'react';
import { Dispatch, Textbox, MultiSelect, BaseComponent } from '@Corim/architecture-frontend'
import _ from 'lodash'

import Menu from './components/Menu'
import Snowflake from './components/physics/Snowflake'

import etLightsSmall from './media/ETLightsSmallLogo.svg'
import etLightsBig from './media/ETLightsBigLogo.svg'
import tree from './media/Tree.svg'
import lightningBolt from './media/LightningBolt.svg'
import busyCrowd from './media/BusyCrowd.png'
import grantAwardPoster from './media/Grant Award Video.png'
import lightControlBetaTest from './media/Light Control Beta Test.png'
import lightshowBetaPoster from './media/Lightshow Beta Poster.png'
import simulatorPoster from './media/SimulatorPoster.png'
import ditherLogoWhite from './media/ditherlogowhite.svg'
import allanICS from './media/allanics.svg'
import etschoolslogo from './media/etschoolslogo.svg'
import chamberLogo  from './media/ChamberLogoBlack.png'
import easttroybreweryLogo from './media/East Troy Brewery Logo.png'
//import taylorsLogo from './media/Taylors Logo.png'
import dancePromoPoster from './media/DancePromo Poster.png'
import Contact from './components/contact/contact'
import lightsTeaser from './media/ET Teaser Small.mp4'
import lightsTeaserPoster from './media/Teaser Video Poster.png'
import travelWisconsin from './media/travelwisconsin.png'
import midwestLogo from './media/Midwest Logo.svg'
import milwaukeeMagazineLogo from './media/MilwaukeeMagazine Logo.svg'
import journalSentinelLogo from './media/JournalSentinel Logo.svg'
import girlWithDogInTunnel from './media/GirlWithDogInTunnel.png'
import ballDrop from './media/BallDrop.png'
import taylorsLogo from './media/Taylors Logo.png'
import candyCaneAdventure from './media/CandyCaneAdventure.png'
import skateboardTunnel from './media/SkateboardTunnel.png'
import santaAndMrsClaus from './media/Santa&Mrs.jpg'
import wineWalkLogo from './media/WineWalkLogo.png'
import holidayShow from './media/HolidayShow.png'
import easttroylive from './media/easttroylive.jpg'
import saucedLogo from './media/Sauced Neon Logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThermometerQuarter, faChevronCircleRight, faChevronCircleLeft, faChevronCircleUp, faChevronCircleDown, faFan, faUserCircle } from '@fortawesome/free-solid-svg-icons'

import { map, filter, share, switchMap, mergeMap, catchError, startWith, debounceTime, delay, tap, scan, concatMap, take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { from, fromEvent, of, combineLatest, timer, merge } from 'rxjs'

fromEvent(window, 'resize')
  .pipe(
    map(e => ({ height: window.innerHeight, width: window.innerWidth, clientHeight: window.clientHeight, ratio: window.devicePixelRatio, extra: Math.random()})),
    startWith({ height: window.innerHeight, width: window.innerWidth, clientHeight: window.clientHeight, ratio: window.devicePixelRatio})
  )
  .subscribe(e => Dispatch.nextState('ui', { window: e }))

const FaqItem = (props) => {
  return (
    <>
      <div className='pt4 f4 fw8'>{props.question}</div>
      <div className='pt2 f5 tj' style={{whiteSpace: 'pre-wrap'}}>{props.answer}</div>
    </>
  )
}

const VideoItem = (props) => {
  return (
    <div className='flex flex-column items-center'>
      

          <video className='w-100 ba br3 bw2 b--white bg-white flex' width="100%" style={{objectFit: 'cover'}} poster={props.poster} preload='metadata' controls>
              <source src={props.video} type="video/mp4"/>
            Your browser does not support the video tag.
            </video>
          <div>
            <div className='pt3 f3 fw8 white tl'>{props.title}</div>
            <div className='pt2 pb5 f5 fw5 white tj' style={{whiteSpace: 'pre-wrap'}}>{props.description}</div>
          </div>
    </div>
  )
}

const AttractionItem = (props) => {
  return (
    <div class='ph3-ns'>
      <div class='pt5 pt5-ns ph5-ns tl mw7'>
        <div class='f2 f1-ns pink fw8'>{props.title}</div> 
        <div class='pt1 fw7 dark-gray f6 f5-ns'>{props.dates}</div>


        <div className='pt3 pt4-ns w-100' style={{lineHeight:0}}>
          <img className='w-100 br3' src={props.image} />
        </div>
        <div class='pt4 dark-gray f5 f5-ns fw4' dangerouslySetInnerHTML={{__html: props.description}}></div>
      </div>
      {props.isLast ?
        <div></div>
        : 
        <div className='w-100 pt5 bg-white flex justify-center'>
          <div className='w-100 bg-pink' style={{height:1}}></div>
        </div>
      }
    </div>
  )
}

const App = (props) => {
  const width = props.ui.window.width > 740 ? 740 : props.ui.window.width - 45
  //const offsetHeight = props.ui.window.height - props.ui.window.clientHeight
  //console.log(offsetHeight)
  return (
    <div className="min-vh-100 w-100 avenir pb4 bg-black white justify-center">
      <Snowflake />
      <div className='fixed w-100 flex justify-between bg-black bb b--dark-gray shadow-3' style={{zIndex:10000}}>
        <div className='w-100 h3 pl4-ns pt2 pb2 pl3 relative' style={{top:5}}>
          <img className='h-100' src={etLightsSmall}/>
          <div className='relative' style={{left: 7, top:-14}}>Dec 2 - Dec 31</div>
        </div>
        <div className='pr4-ns pt1' style={{}}>
          <Menu />
        </div>
      </div>
      <div className='min-vh-100 vh-100 flex flex-column justify-between'>

          <div className='h-100 w-100 bg-black flex items-center justify-center'>
       
          <div className='w-100 mw6 ph4 ph5-ns pt4'>
                <img className='pt1 ph4' src={tree}/>
            </div>
              {/* <div className='w-100 pt1 flex justify-center'>
                <div className=' pv3 ph4 br3 bg-light-gray moon-gray f4 fw5 ba b--white tc'>Control the Lights </div>
                <a class='link' href="https://www.youtube.com/watch?v=vyK3GDdjOMI"> <div className=' pv3 ph4 br3 bg-blue white f4 fw5 ba b--white tc pointer'>Control the Lights</div> </a>
                <a className='link white' target='_blank' href='https://www.easttroy.live'>
                  <div className=' pv3 ph4 br3 bg-light-gray moon-gray f4 fw5 ba b--white'>Control the Lights!</div>
                  </a>
              </div> */}
              {/* <div className='w-100 ph4 pt6 pt5-ns mt4 mt1-ns mw7 f1 fw8 white tl tc-ns'>Control the lights.</div>
              <div className='w-100 ph4 pt4 pt1-ns mt4 mt0-ns mw7 f1 fw8 pink tr tc-ns'>Watch the show.</div>
              <div className='w-100 ph4 pt4 pt1-ns mt4 mt0-ns mw7 f1 fw8 white tl tc-ns'>Make the magic!</div> */}
          </div>

        <div className='w-100 flex flex-column items-center'>
          {/* <div className='w-100 mw8 pl3 pb1 pink fw7 fw7-ns tc f4'>Experience a different type of holiday light show!</div> */}
          <div className='relative w-100 pb2 pt3 ph3 ph5-ns bg-white flex flex-column items-center bt bb b--pink bw3' style={{top:-75}}>
            <div className='w-100 black tc fw7 f5 f3-ns'>AS SEEN IN</div>
            <div className='pt3 pb2 w-100 mw8 flex justify-between'>
              <div className='pr3'>
                <a href='https://www.midwestliving.com/travel/around-region/unique-midwest-holiday-light-displays/' ><img className='w-100' src={midwestLogo} /></a>
              </div>
              <div className='pr2'>
                <a href='https://www.jsonline.com/story/sports/2020/12/11/chin-up-east-troy-lights-display-more-than-walk-park/3870249001/?fbclid=IwAR0TSdhGJs3tjFLFmZq5sJm0OBuQD2hubT6WRdbtra8uWGbnAX7wre8Xj6k' ><img className='w-100' src={journalSentinelLogo} /></a>
              </div>
              <div className=''>
                <a href='https://www.milwaukeemag.com/10-milwaukee-area-spots-to-see-holiday-lights/' ><img className='w-100' src={milwaukeeMagazineLogo} /></a>
              </div>
            </div>
          </div>
          {/* <div className='w-100 bg-black h1'></div>
          <div className='w-100 bg-black h2'></div> */}
        </div>
      </div>
      <div className='w-100 bg-black flex flex-column items-center'>
        <div className='w-100 b--black' style={{lineHeight:0}}>
          <img className='w-100' src={girlWithDogInTunnel} />
        </div>
        <div className='w-100 bg-black h1'></div>
        <div className='w-100 bg-black h3'></div>
        <div id='watch' className='pt5 pt6-ns w-100 mw8 bg-pink flex flex-column items-center'>
          <div className='w-100 ph4 mw7'>
            <div className='f2 f1-ns fw7 '>The Light Show Adventure</div>
            <div className='pt4'>Dazzling Interactive Light Tunnels, Hot Chocolate, Cookie Decorating & Holiday Performances!
            <br/><br/>
            Join us this December for a one-of-a-kind experience. Watch the lights come to life with the new immersive "East Troy Lights Live!" every Friday & Saturday or grab a wand, a comic, and start The Candy Cane Adventure! 
            <br/><br/>Take control the lights and help us make the magic!
            </div>
          </div>
          {/* <div>A Dark Winter Night</div>
          <div>On a dark winter night, on the historic East Troy Square, Santa and his elves were hard at work preparing 
            presents for the children. But all was not right...the lights...were dimming...
          </div> */}
          <div className='w-100 pt4 pt5-ns pb5 pb6-ns flex flex-column items-center'>
            <iframe className='br3' width={width} height={ width * .5625 } src="https://www.youtube.com/embed/FW-XUl0vijM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
      </div>

  <div className='flex flex-column items-center bg-black'>
      <div id='attractions' class='w-100'>
        <div class='w-100 pt5 pt6-ns pb5 bg-black flex flex-column items-center'>
          <div className='w-100 mw6 ph4 f1 fw7 white tc'>Attractions</div>
            <div className='w-100 mw6 ph4 ph0-ns pt3 f4 fw4 white tc'>
            Visit Historic Downtown East Troy and experience the magic!
            </div>
        </div>

        {/* <div className='w-100 pt2 ph4 bg-white flex justify-center'>
          <div className='w-100 bg-gray mw6' style={{height:1}}></div>
        </div> */}
      </div>

      <div className='w-100 mw8 pb5 ph4 ph5-ns bg-white flex flex-column items-center'>

            {/* <div className='w-100 mw6 ph4 pt3 pt6-ns mt4 mt0-ns f2 fw8 pink'>Event Lineup</div> */}
            <div className='w-100 mw7'>

              {/* <AttractionItem 
                title='Light Show Performances' 
                dates='Every Friday & Saturday at 7pm' 
                image={skateboardTunnel} 
                description="Bring the lights, bring the music, bring the actors, and you've got magic! Join us every Friday and Saturday for
                a one of a kind holiday musical performance! <br/><br/> 
                Grab hot chocolate, chili, cookies, and a host of other goodies while you watch the show!
                <br/><br/>
                Note: if weather is especially bad we will post on our Facebook page if the performance will be canceled."
              /> */}
              <AttractionItem 
                title='Light Shows' 
                dates='December 2nd - December 31st' 
                image={holidayShow} 
                description='Grab dinner, take a stroll around the square, & watch the lights twinkle to music every hour on the hour from 5 to 7 pm!'
              />
              <AttractionItem 
                title='East Troy Lights Live!' 
                dates='Every Friday & Saturday at 6 pm & 7 pm' 
                image={easttroylive} 
                description='Dazzling light tunnels, hot chocolate, cookie decorating & holiday performances!
                Watch the Lights come to life with a special immersive show!'
              />
              <AttractionItem 
                title='The Candy Cane Adventure!' 
                dates='December 8th - December 31st' 
                image={candyCaneAdventure} 
                description='Get a wand. Grab a comic. Find the candy canes. Unlock their powers. Control the lights!'
              />
              <AttractionItem 
                title='Santa on the Square' 
                dates='December 2nd' 
                image={santaAndMrsClaus} 
                description="<a href='https://easttroy.org/events/2023/dec/2/santa-square/'>Santa comes to East Troy </a> via the 114-year-old East 
                Troy Electric Railroad Santa Parade Train."
              />
              <AttractionItem 
                title='East Troy Winter Wine Walk' 
                dates='December 8th' 
                image={wineWalkLogo} 
                description="Enjoy downtown East Troy while you sample unique wine selections. 
                <a href='https://easttroy.org/events/2023/dec/8/east-troy-winter-wine-walk-tickets-go-sale-nov-1/'>Sign up.</a>"
              />
              <AttractionItem 
                title="New Year's Eve Ball Drop!" 
                dates='December 31st at 8pm and midnight!' 
                image={ballDrop} 
                description="<a href='https://easttroy.org/events/2023/dec/31/east-troys-new-years-eve-ball-drop/'>Watch the ball drop </a> live at 8pm (family friendly) and - of course - at midnight!"
                isLast={true}
              />
            </div>
            <a className='link' target='_blank' href='https://form.jotform.com/93377961558979'><div className='dib mt5 pv3 ph4 bg-pink br3 fw8 white tc'>Donate</div></a>
            <div className='mw6 pt3 ph4'><img className='' src={chamberLogo}/></div>
          </div>
        </div>
      <div className='w-100 bg-pink flex flex-column items-center'>
        {/* <div className='f2 fw8 tc'>Get excited!</div> */}

        {/* <div className='pt4 w-100'>
          <img className='' src={busyCrowd}/>
        </div>
        <div className='ph4 pt4 f1 fw8 tc'> Santa's been busy! Check it out!</div> */}
        <div id='clips' className='pt5 pb4 ph3 mw7'>
          <div className='pt4 f1 fw8 white tc tl-ns'>MEDIA</div>
          <div className=' pb4 f4 fw6 white tc tl-ns'>Check out Our Latest News & Showcases!</div>
          <div className='pt2 pb5'>
            <iframe width={width} height={ width * .5625 } src="https://www.youtube.com/embed/86Npm4Owvbc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div className='pt2 f3 fw7 tl'>East Troy Lights Origin Story!</div>
            <div className='pt2 f5 fw5 tl'>Check out how the East Troy Lights came to be!</div>
          </div>
          <div className='pt2 pb5'>
            <iframe width={width} height={ width * .5625 } src="https://www.youtube.com/embed/s8WmJkCr9x4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div className='pt2 f3 fw7 tl'>Be Like Santa!</div>
            <div className='pt2 f5 fw5 tl'>Check out East Troy Santa Style!</div>
          </div>
          <VideoItem title='East Troy Lights Teaser' description="A short teaser of the East Troy Lights in action!" video={lightsTeaser} poster={lightsTeaserPoster}/>
        </div>
        <div className='w-100 bg-black flex flex-column items-center '>
          <div className='w-100 mw6'>
            {/* <div className='pt4 w-100 flex justify-center'><img className='' src={lightningBolt}/></div> */}
            <div id='sponsors' className='pt6 pb3 w-100 ph4'>
            <div className='f1 fw8 pink tc'>SPONSORS</div>
            <div className='mt3 w-100 bg-white' style={{height:1}}></div>
                  {/* <div className='f1 fw8 pink'>SPONSORS</div>
                  <div className='pt4 f4 fw8 white'>We're incredibly thankful for our sponsors!</div> */}
                  <div className='pt4 ph4 ph0-ns w-100 flex flex-column items-center'>
                    <a className='w-100 mw5-ns ph1 mt2 mh4 link white' target='_blank' href='http://dither.studio'> 
                      <div>
                        <img className='w-100' src={ditherLogoWhite}/>
                      </div> 
                    </a>
                    <a className='w-100 mw5-ns mt3 mh4 link white' target='_blank' href='https://www.getsauced.pizza/'> 
                      <div>
                        <img className='w-100' src={saucedLogo}/>
                      </div> 
                    </a>
                    <a className='w-100 mw5-ns ph1 mt3 mh4 link white' target='_blank' href='http://etbrew.com'> 
                      <div>
                        <img className='w-100' src={easttroybreweryLogo}/>
                      </div> 
                    </a>
                    <a className='w-100 mw5-ns ph1 mt4 mh4 link white' target='_blank' href='https://www.jrtaylorandsons.com/'> 
                      <div>
                        <img className='w-100' src={taylorsLogo}/>
                      </div> 
                    </a>
                    <a className='w-100 mw5-ns mt4 mh4 link white' target='_blank' href='https://allan-ics.com/'> 
                      <div>
                        <img className='w-100' src={allanICS}/>
                      </div> 
                    </a>
                    <a className='w-100 ph2 mw5-ns mt4 pt3 mh4 link white' target='_blank' href='https://www.easttroy.k12.wi.us/'> 
                      <div>
                        <img className='w-100' src={etschoolslogo}/>
                      </div> 
                    </a>
                  </div>
                </div>
                <div id='sponsor' className='pt5 pt6-ns w-100 ph4'>
              <div className='f1 fw8 pink'>WHY SPONSOR?</div>
              <FaqItem question='Get noticed! Make a difference!' answer={`Sponsoring East Troy Lights – a spectacular interactive light show – let’s us do the work for you while helping bring a little magic to the world. The event is estimated to draw more than 15,000 local, day trip and overnight visitors and upwards of $700,000 in spending.
              

The one-of-a-kind experience will draw both young and old alike to East Troy for an opportunity to take control of the Christmas lights all around the downtown historic East Troy Square. Using RFID wands visitors from around the state and country will have the opportunity to not just see dazzling Christmas light displays, but participate in the show itself while literally walking amongst the lights.
`}/>
            </div>
            <div className='pt4 w-100 flex justify-center'>
              <a className='w-100 mw5-ns mh4 mt2 link white' target='_blank' href='https://form.jotform.com/202118187550956'>
                <div className='w-100 pv3 tc br2 bg-pink white f4 fw5 ba b--white'>Sponsor!</div>
              </a>
            </div>
            <div id='getinvolved' className='mt5 pt4 w-100 ph4'>
              <div className='f1 fw8 pink'>GET INVOLVED</div>
              <FaqItem question='We need your help!' answer={`From helping decorate, to play-acting, to donations, we need people! We want to bring the community together like never before! This is your opportunity to get involved!
              
Our sponsors and donors are wonderful assets. We are looking for individuals and companies to provide skills, equipment, and materials for the creation and operation of the light show.

Electricians, carpenters, sanitary services, snow removal, materials of every kind, promotion, equipment use, advertising, and printing, are just a sampling of the in kind donations that would help. If you feel you have goods or services you would like to provide to us, please reach out!`}/>
            </div>
            <div className='pt4 w-100 flex justify-center'>
              <a className='w-100 mw5-ns mh4 mt2 link white' target='_blank' href='https://form.jotform.com/93377961558979'><div className='w-100 pv3 tc br2 bg-pink white f4 fw5 ba b--white'>Donate</div></a>
            </div>
            <div id='simulator' className='pt5 pt6-ns w-100 ph4'>
              <div className='f1 fw8 pink'>LIGHT SIMULATOR</div>
              <FaqItem question='Try our light show simulator and submit your light show for review' answer='The light show simulator was originally designed to be used by the East Troy Middle School and East Troy High School students. However, the simulator is now available for anyone to try. Want to try making a cool light show? Give it a whirl! And then submit your show!'/>
            </div>
            <div className='pt4 w-100 flex justify-center'>
              <a className='w-100 mw5-ns mt2 mh4 link white' target='_blank' href='http://easttroy.show'><div className='pv3 tc br2 bg-pink white f4 fw5 ba b--white'>The Simulator</div></a>
            </div>
            <div id='faq' className='pt5 pt6-ns w-100 ph4'>
              <div className='f1 fw8 pink'>FAQ</div>
              <FaqItem question='What is the East Troy Light Show?' answer='A spectacular interactive light show (utilizing cutting-edge technology produced by the East Troy Area Chamber of Commerce, a 501 (c) 6 non-proﬁt organization, and Dither Studio, along with Allan ICS and the East Troy Community School District.'/>
              {/* <FaqItem question='How can I make a light show?' answer='We have two ways to get involved with making a light show. The first is to use your mobile phone. After Dec. 5, youll be able to control the lights from your phone. The second way is to use our light simulator and submit a light show (see above).'/> */}
              <FaqItem question="I'd like to help hang Christmas lights, what should I do?" answer="We're glad you asked! Contact us in the form below, and we'll get you setup to help us with decorating when the time is ready!"/>
              {/* <FaqItem question="How do I take control of the Lights?" answer="Play the game! Earn power by playing the game, or purchase power inside the game, to take control of the lights!"/> */}
              <div class='pt4 flex flex-column'>
                <div className='fw7 f3'>What technology is used to control the lights and the candy canes?</div>
                <div className='pt3'>We use <a className='link green fw8 underline' href='https://www.meetsvet.com'>Svet!</a> Svet is both a hardware and software solution which allows us to build out not just the light shows, but interactive characters and adventures like the candy canes.</div>
              </div>
            </div>
            {/* <div id='gameFaq' className='pt5 pt6-ns w-100 ph4'>
              <div className='f1 fw8 pink'>GAME FAQ</div>
              <FaqItem question='Iphone: I am getting the error: "Enable location permissions and try again"' answer='Three different settings need to be checked. The first is under Settings -> Privacy -> Location Services and the be suer that "Location Services" is "On." The second setting is under Settings -> Safari -> Location and make sure this is set to "Ask." The third setting to check is Settings -> Privacy -> Location Services -> Safari Websites -> to "While Using the App" or "Ask Next Time"'/>
              <FaqItem question='Android: I am getting the error: "Enable location permissions and try again"' answer=''/>
              <FaqItem question="My locations isn't accurate." answer='Location accuracy can decrease inside buildings, while connected to certain wifi networks, or when low on battery.'/>
              <FaqItem question="I've found a bug, what should I do?" answer='Contact us below and send us a message! Include your username and a brief description of what you encountered. Oh, and thanks for playing!'/>
            </div> */}
          </div>
      
          <div className='pt4-ns'>
            <Contact />
          </div>
          {/* <div className='w-100 bg-black flex flex-column items-center '>
            <div className='w-100 mw6'>
              <div id='sponsors' className='pt5 pb3 w-100 ph4'>
                  <div className='f1 fw8 pink'>SPONSORS</div>
                  <div className='pt4 f4 fw8 white'>We're incredibly thankful for our sponsors!</div>
                  <div className='pt5 pt4-ns ph4 ph0-ns w-100 flex flex-column items-center'>
                    <a className='w-100 mw5-ns ph1 mt2 mh4 link white' target='_blank' href='http://dither.studio'> 
                      <div>
                        <img className='w-100' src={ditherLogoWhite}/>
                      </div> 
                    </a>
                    <a className='w-100 mw5-ns mt4 mh4 link white' target='_blank' href='https://allan-ics.com/'> 
                      <div>
                        <img className='w-100' src={allanICS}/>
                      </div> 
                    </a>
                    <a className='w-100 ph2 mw5-ns mt4 pt3 mh4 link white' target='_blank' href='https://www.easttroy.k12.wi.us/'> 
                      <div>
                        <img className='w-100' src={etschoolslogo}/>
                      </div> 
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          <div className='relative w-100 pa3 bg-white black f7 tc' style={{top:70}}>Copyright East Troy Lights 2024</div>
        </div>
      </div>
    </div>
  );
}

export default App;
