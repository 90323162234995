import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'tachyons'
import { Dispatch, BaseComponent, Request } from '@Corim/architecture-frontend'
import './engines'
import Apollo from './apollo'
import './index.css'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-174485958-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Request(Dispatch, Apollo)

ReactDOM.render(
  <React.StrictMode>
    <BaseComponent Component={App} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
